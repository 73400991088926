<template src="./MasterDataForm.html"></template>
<style scoped lang="scss" src="./MasterDataForm.scss"></style>

<script>
import { MasterDataTypes } from "@/constants";

export default {
  name: 'MasterDataForm',
  props: {
    isLoading: Boolean,
    isEdit: Boolean,
    masterData: {},
  },
  watch: {
    masterData(value) {
      this.formData.type = value.type || 0;
      this.formData.name = value.name || '';
      this.isCanNotUpdateType = value.canNotUpdateType || false
    },
  },
  data: function () {
    return {
      formData: {
        type: '',
        name: '',
      },
      rules: {
        type: [
          {required: true, message: 'マスタデータの種類が必要です', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '内容が必要です', trigger: 'change'},
          {max: 1000, message: '最大値1000文字を超えてはなりません。', trigger: 'blur' }
        ],
      },
      listType: MasterDataTypes,
      isCanNotUpdateType: false
    }
  },
  computed: {
    textSubmitButton() {
      if (this.isEdit) return 'マスタ編集'
      return '新規登録'
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.form.validate();

      if (!valid) return;

      this.$emit('submit', this.formData)
    },

    onCancel() {
      return this.$router.push({name: 'routes.masterManagement'})
    },
  }
}
</script>
